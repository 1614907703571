import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { UserContext } from '../store/user-context';
import { SearchContext } from '../store/search-context';
import SearchBar from './SearchBar';
import logo from '../assets/tp-logo.svg';

const navigations = [
  { id: 1, name: '練習', href: '/' },
  // { name: 'Real', href: '#' },
  { id: 2, name: '筆記', href: '/notes' },
];
const backendLoginPath = process.env.REACT_APP_EXPRESS_DISCORD_AUTH;
const userMenus = [
  {
    userLoggedIn: false,
    name: 'Log in with Discord',
    href: `${backendLoginPath}`,
    className:
      'rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
  },
  {
    userLoggedIn: true,
    name: '發表練習',
    href: '/create-exercise',
    className:
      'rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
  },
  {
    userLoggedIn: true,
    name: '我的寶庫',
    href: '/my-treasury/exercises',
    className:
      'hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900',
  },
];

export default function Header() {
  const { user, isLoading } = useContext(UserContext);

  if (user.uid && user.access) {
    console.log('user logged in');
  } else if (!user.access) {
    console.log("You don't have access right");
  } else {
    console.log('please log in');
  }

  return isLoading ? <LoadingHeader /> : <SiteHeader />;
}

export function LoadingHeader() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className='bg-white'>
      <nav
        className='mx-auto flex max-w-7xl items-center justify-between gap-x-6 py-2 lg:px-0'
        aria-label='Global'
      >
        <div className='flex lg:flex-1'>
          <Link to='/' className=''>
            <span className='sr-only'>Trader Piece</span>
            <img className='h-20 w-auto' src={logo} alt='Trader Piece Logo' />
          </Link>
        </div>
        <div className='hidden lg:flex lg:gap-x-12'>
          {navigations.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className='text-sm font-semibold leading-6 text-gray-900'
            >
              {item.name}
            </Link>
          ))}
        </div>

        <div className='flex flex-1 items-center justify-end gap-x-6'>
          <div className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm opacity-50 cursor-not-allowed'>
            Loading...
          </div>
        </div>

        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
      </nav>
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-10' />
        <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center gap-x-6'>
            <Link to='#' className='-m-1.5 p-1.5'>
              <span className='sr-only'>Trader Piece</span>
              <img className='h-16 w-auto' src={logo} alt='Trader Piece Logo' />
            </Link>

            <Link
              to='/create-exercise'
              className='ml-auto rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm opacity-50 cursor-not-allowed'
            >
              Loading...
            </Link>

            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                {navigations.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}

export function SiteHeader() {
  const { user, isLoading } = useContext(UserContext);
  const { handleSearch, setSearchText } = useContext(SearchContext);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  function submitSignOut() {
    console.log('click log out');
    const removeCookie = (name) => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.traderpiece.com; path=/;`;
    };

    removeCookie('uid');
    console.log('cookie is removed');
  }

  function handleLogoClick() {
    handleSearch(''); // Call handleSearch with an empty string to clear the search bar text
    setSearchText('');
  }

  return (
    <header className='bg-white'>
      <nav
        className='mx-auto flex max-w-7xl gap-x-6 items-center py-2 lg:px-0'
        aria-label='Global'
      >
        <div className=''>
          <Link to='/' className='' onClick={handleLogoClick}>
            <span className='sr-only'>Trader Piece</span>
            <img className='h-20 w-auto' src={logo} alt='Trader Piece Logo' />
          </Link>
        </div>
        <div className='flex flex-1 items-center gap-x-10 justify-bwtween'>
          <div className='w-4/6'>
            <SearchBar />
          </div>

          <div className='hidden lg:flex lg:gap-x-12'>
            {navigations.map((item) => (
              <Link
                key={item.id}
                to={item.href}
                className='text-sm font-semibold leading-6 text-gray-900'
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>

        <div className='flex items-center justify-end gap-x-6'>
          {userMenus.map((menu) => {
            if (menu.userLoggedIn && user.uid) {
              return (
                <Link to={menu.href} className={menu.className}>
                  {menu.name}
                </Link>
              );
            } else if (!menu.userLoggedIn && !user.uid) {
              return (
                <Link to={menu.href} className={menu.className}>
                  {menu.name}
                </Link>
              );
            }
            return null;
          })}
          {user.uid ? (
            <a
              href='/'
              className='hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900'
              onClick={submitSignOut}
            >
              Log out
            </a>
          ) : (
            ''
          )}
        </div>

        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
      </nav>
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-10' />
        <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center gap-x-6'>
            <Link to='#' className='-m-1.5 p-1.5'>
              <span className='sr-only'>Trader Piece</span>
              <img className='h-16 w-auto' src={logo} alt='Trader Piece Logo' />
            </Link>
            {user.uid ? (
              <Link
                to='/create-exercise'
                className='ml-auto rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                Create Post
              </Link>
            ) : (
              <Link
                to={backendLoginPath}
                className='ml-auto rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                Log In
              </Link>
            )}

            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                {navigations.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              {user.uid ? (
                <div className='py-6'>
                  <Link
                    to='/my-treasury/exercises'
                    className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                  >
                    我的寶庫
                  </Link>
                  <a
                    href='/'
                    className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                    onClick={submitSignOut}
                  >
                    Log out
                  </a>
                </div>
              ) : (
                <div className='py-6'></div>
              )}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
