import { useContext } from 'react';
import { UserContext } from './store/user-context';
import { SearchContext } from './store/search-context';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from './components/Header';
import LoadingBar from './components/LoadingBar';
import SearchResult from './pages/SearchResult';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import './App.css';

export default function App() {
  const { user, isLoading } = useContext(UserContext);
  const { searchText, searchResults } = useContext(SearchContext);

  return (
    <>
      <Header />
      {isLoading ? (
        <div>
          <Helmet>
            <title>Trader Piece</title>
          </Helmet>
          <LoadingBar />
        </div>
      ) : (
        <div>
          <Helmet>
            <title>Trader Piece</title>
          </Helmet>

          <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
            {searchText ? <SearchResult /> : <Outlet />}
          </div>
        </div>
      )}
    </>
  );
}
